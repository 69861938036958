import React from 'react';
import { Link } from 'react-router-dom';
import { Button, CircularProgress } from '@ayx/onyx-ui';
import {
	GenericPage,
	useEffectOnce,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { info } from 'enums';

export default function Logout({ redirectTo }: { redirectTo: string }) {
	const { logout } = useAuth();

	const urlParams = new URLSearchParams(window.location.search);
	const sloParam = urlParams.get('slo');

	useEffectOnce(() => {
		logout(sloParam === 'true').catch((error) => {
			console.error(error);
		});
	});
	return (
		<GenericPage
			icon={<CircularProgress size={52} />}
			title="Signing Out..."
			text={info.AUTHORIZING}
			actions={
				<Button
					data-cy="logout-page-button-home"
					variant="contained"
					color="primary"
					className="min-w-24"
					component={Link}
					to={redirectTo}
					replace
				>
					Return Home
				</Button>
			}
		/>
	);
}
